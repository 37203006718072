.main {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title h1 {
  font-weight: 700;
  font-size: 32px;
}

.title h2 {
  font-size: 15px;
  list-style: circle;
  font-weight: 400;
  color: #79828b;
  transform: translate(14px, 0);
}

.images {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.imgWrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.imgWrapper img {
  width: 100%;
  max-height: 690px;
  object-fit: contain;
  backdrop-filter: blur(10px);
}
