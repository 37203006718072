* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-family: sans-serif;
}

body {
  width: 100%;
  display: flex;
  justify-content: center;
}

#root {
  width: 100%;
  max-width: 768px;
}
