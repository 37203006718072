.notFound {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
}

.notFound h1 {
  font-size: 100px;
  color: #333;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.notFound p {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
}
